<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSave()"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model.trim="nombre"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(nombre),
                    rules.maxLength(nombre, 50)
                  ])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="codigoInterno"
                label="Código interno"
                dense
                outlined
                :rules="
                  codigoInterno
                    ? rules.required.concat([
                        rules.maxLength(codigoInterno, 1)
                      ])
                    : []
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="codigoMedPre"
                label="Código para medicina prepaga"
                dense
                outlined
                :rules="
                  codigoMedPre
                    ? [rules.maxLength(codigoMedPre.toString(), 4)]
                        .concat(rules.positiveNumber)
                        .concat(rules.required)
                    : []
                "
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <template>
                <v-container class="d-flex align-items-center" fluid>
                  <label class="mr-2 pt-2" for="esTitular">Titular</label>
                  <v-switch
                    v-model="esTitular"
                    id="esTitular"
                    @change="validarParentesco('esTitular')"
                  >
                  </v-switch>
                </v-container>
              </template>
            </v-col>

            <v-col cols="3">
              <template>
                <v-container class="d-flex align-items-center" fluid>
                  <label class="mr-2 pt-2" for="esConyuge">Cónyuge</label>
                  <v-switch
                    v-model="esConyuge"
                    id="esConyuge"
                    @change="validarParentesco('esConyuge')"
                  ></v-switch>
                </v-container>
              </template>
            </v-col>

            <v-col cols="3">
              <template>
                <v-container class="d-flex align-items-center" fluid>
                  <label class="mr-2 pt-2" for="esHijo">Hijo</label>
                  <v-switch
                    v-model="esHijo"
                    id="esHijo"
                    @change="validarParentesco('esHijo')"
                  ></v-switch>
                </v-container>
              </template>
            </v-col>

            <v-col cols="3">
              <template>
                <v-container class="d-flex align-items-center" fluid>
                  <label class="mr-2 pt-2" for="esAdicionalAlCargo"
                    >Adicional al cargo</label
                  >
                  <v-switch
                    v-model="esAdicionalAlCargo"
                    id="esAdicionalAlCargo"
                    @change="validarParentesco('esAdicionalAlCargo')"
                  ></v-switch>
                </v-container>
              </template>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <ConfirmDialog
        :text="textConfirmDialog"
        :openConfirm.sync="openConfirmDialog"
        @update:openConfirm="cancelar"
        @onConfirm="save(saveRegistro)"
      />
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "EditParentescos",
  components: { ConfirmDialog },
  props: {
    parentescosObject: {
      type: Object,
      default: null
    },
    configParentescos: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    isFormValid: false,
    enums: enums,
    formEditTitle: enums.titles.EDIT_PARENTESCO,
    newTitle: enums.titles.WEB_NEW_PARENTESCO,
    rules: rules,
    nombre: null,
    esTitular: false,
    esHijo: false,
    esConyuge: false,
    esAdicionalAlCargo: false,
    codigoMedPre: null,
    codigoInterno: "",
    openConfirmDialog: false,
    textConfirmDialog: "",
    saveRegistro: null
  }),
  created() {
    if (this.parentescosObject) {
      this.setParentescos();
    } else {
      this.newParentescos();
    }
  },

  methods: {
    ...mapActions({
      postParentescos: "configAfiliaciones/postParentescos",
      setAlert: "user/setAlert"
    }),
    async newParentescos() {
      this.formEditTitle = this.newTitle;
    },
    setParentescos() {
      this.nombre = this.parentescosObject.parNombre;
      this.esTitular = this.parentescosObject.parTit;
      this.esConyuge = this.parentescosObject.esConyuge;
      this.esHijo = this.parentescosObject.esHijo;
      this.esAdicionalAlCargo = this.parentescosObject.esAdicionalaCargo;
      this.codigoInterno = this.parentescosObject.parCod.trim();
      this.codigoMedPre = this.parentescosObject.parCodMePre;
      this.codigoMedPre = this.codigoMedPre?.toString();
    },

    validarParentesco(onlyParentesco) {
      switch (onlyParentesco) {
        case "esTitular":
          this.esHijo = false;
          this.esConyuge = false;
          this.esAdicionalAlCargo = false;
          break;

        case "esHijo":
          this.esTitular = false;
          this.esConyuge = false;
          this.esAdicionalAlCargo = false;
          break;

        case "esConyuge":
          this.esHijo = false;
          this.esTitular = false;
          this.esAdicionalAlCargo = false;
          break;

        case "esAdicionalAlCargo":
          this.esHijo = false;
          this.esConyuge = false;
          this.esTitular = false;
          break;

        default:
          break;
      }
    },

    canSave() {
      this.saveRegistro = {
        ParNombre: this.nombre,
        ParCod: this.codigoInterno,
        ParTit: this.esTitular,
        EsHijo: this.esHijo,
        esConyuge: this.esConyuge,
        EsAdicionalaCargo: this.esAdicionalAlCargo,
        ParCodMePre: this.codigoMedPre,
        parId: this.parentescosObject?.parId
      };

      for (let i = 0; i < this.configParentescos.length; i++) {
        if (
          this.configParentescos[i].parCod == this.saveRegistro.ParCod &&
          this.configParentescos[i].parId != this.saveRegistro.parId
        ) {
          this.textConfirmDialog = `El código interno "${this.configParentescos[i].parCod}" ya se encuentra en "${this.configParentescos[i].parNombre}". `;
          break;
        }
      }
      for (let j = 0; j < this.configParentescos.length; j++) {
        if (
          this.configParentescos[j].parCodMePre ==
            this.saveRegistro.ParCodMePre &&
          this.saveRegistro.ParCodMePre &&
          this.configParentescos[j].parId != this.saveRegistro.parId
        ) {
          if (this.textConfirmDialog == "") {
            this.textConfirmDialog = `El código de medicina prepaga "${this.configParentescos[j].parCodMePre}" ya se encuentra en "${this.configParentescos[j].parNombre}". `;
            break;
          } else {
            this.textConfirmDialog += `El código de medicina prepaga "${this.configParentescos[j].parCodMePre}" ya se encuentra en "${this.configParentescos[j].parNombre}". `;
            break;
          }
        }
      }
      this.textConfirmDialog += "¿Desea confirmar los cambios?";
      if (this.textConfirmDialog === "¿Desea confirmar los cambios?") {
        this.save(this.saveRegistro);
      } else {
        this.openConfirmDialog = true;
      }
    },

    cancelar() {
      (this.saveRegistro = null), (this.textConfirmDialog = "");
    },

    async save(data) {
      const res = await this.postParentescos(data);

      this.openConfirmDialog = false;
      this.textConfirmDialog = "";

      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      }

      this.closeModal();

      this.saveRegistro = null;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
